<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">
      <b-card class="mb-0">
        <b-link class="brand-logo">
          <vuexy-logo />
        </b-link>
        <b-card-title class="mb-1">
          Parolanızı mı unuttunuz? 🔒
        </b-card-title>
        <b-card-text class="mb-2">
          Parola sıfırlama linkini almak için e-postanızı girin. 
        </b-card-text>

        <!-- form -->
        <validation-observer ref="rules">
          <b-form
            class="auth-forgot-password-form mt-2"
            @submit.prevent="validationForm"
          >
            <!-- email -->
            <b-form-group
              :label="$t('E-Posta')"
              label-for="forgot-password-email"
            >
              <validation-provider
                #default="{ errors }"
                name="E-Posta"
                rules="required|email"
              >
                <b-form-input
                  id="forgot-password-email"
                  v-model="userEmail"
                  :state="errors.length > 0 ? false:null"
                  name="forgot-password-email"
                  placeholder="ornek@kassyazilim.com"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- submit button -->
            <b-button
              variant="primary"
              block
              type="submit"
            >
            <b-spinner
              v-if="$store.getters.getLoader"
              class="mr-1"
              small
              variant="light"
            />
              {{ $t('Sıfırlama linki gönder') }}
            </b-button>
          </b-form>
        </validation-observer>

        <b-card-text class="text-center mt-2">
          <b-link :to="{ name:'login' }">
            <feather-icon icon="ChevronLeftIcon" />  {{ $t('Girişe Dön') }} 
          </b-link>
        </b-card-text>

      </b-card>
    <!-- /Forgot Password v1 -->
    </div>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import { required, email } from '@validations'
import AlertService from '@/common/alert.service'

export default {
  components: {
    VuexyLogo,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      userEmail: '',
      // validation
      required,
      email,
    }
  },
  methods: {
    validationForm() {
      this.$refs.rules.validate().then(success => {
        if (success) {
          this.$store.dispatch('profileSettings/sendPasswordResetLink', { email: this.userEmail })
            .then(response => {
              if (response.statusCode === 200) {
                AlertService.success(this, response.message, () => {
                  // this.$router.push({ name: 'login' });
                });
              } else {
                AlertService.error(this, response.message);
              }
            }).catch(err => {
              AlertService.error(this, err);
            });
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
